import {
  Icon,
  MediaQuery,
  Body,
  BodyHeavy,
  Caption,
  CaptionHeavy,
  H3Heavy,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

export const BACKGROUND_COLOR_VAR = '--background-color';

export const FilterTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px 24px;
`;

export const FilterTimeHeader = styled.div`
  ${H3Heavy}
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const FilterTimeContent = styled.div`
  ${Body}
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 17px;
`;

export const CloseIcon = styled(Icon)`
  margin-left: 4px;
  cursor: pointer;
  font-size: 24px;
`;

export const FilterItem = styled.button`
  ${Caption}
  flex-direction: row-reverse;
  display: flex;
  background: none;
  padding: 12px;
  height: 40px;
  align-items: center;
  padding: 12px;
  border-radius: 12px;
  color: rgb(${Theme.colors.Alpha});

  &:hover {
    background-color: rgb(${Theme.colors.India});
  }

  &[aria-selected='true'] {
    ${CaptionHeavy}
    background-color: rgb(${Theme.colors.Bravo});
    color: rgb(${Theme.colors.Alpha});
    &::after {
      content: '';
      display: flex;
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background-color: rgb(${Theme.colors.Brand});
      margin-right: 8px;
    }
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${Body}

    &[aria-selected='true'] {
      ${BodyHeavy}
    }
  }
`;
