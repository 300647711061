import { FeaturedStores } from '../../../../../../domain/entities/Stores';
import { getCouponsUrl } from '../../../../../services/urls';
import FeaturedStoresCarouselComponent, {
  CarouselFeaturedStore,
} from './component';

export type EnhancedFeaturedStoresCarouselProps = {
  className?: string;
  featuredStores?: FeaturedStores[];
  locale?: string;
};

const mapFeaturedStoresToFeedStoreCarousel = (
  featuredStores?: FeaturedStores[],
  locale?: string
): CarouselFeaturedStore[] => {
  if (!featuredStores) return [];

  return featuredStores.map(({ id, name, slug }) => ({
    id,
    name,
    url: getCouponsUrl({ storeSlug: slug, locale }),
  }));
};

function EnhancedFeaturedStoresCarousel({
  className,
  featuredStores,
  locale,
}: EnhancedFeaturedStoresCarouselProps) {
  const stores = mapFeaturedStoresToFeedStoreCarousel(featuredStores, locale);

  return (
    <FeaturedStoresCarouselComponent className={className} stores={stores} />
  );
}

export default EnhancedFeaturedStoresCarousel;
