import styled from 'styled-components';
import {
  BodyHeavy,
  BodyLight,
  Button,
  H2Heavy,
  H3Heavy,
  MediaQuery,
  Theme,
} from '@pelando/components';

export const IconWrapper = styled.div`
  font-size: 48px;
  color: rgb(${Theme.colors.Brand});

  i {
    font-size: 48px;
    margin-bottom: 16px;
    color: rgb(${Theme.colors.Brand});
  }

  h3 {
    ${H3Heavy}
    margin-bottom: 8px;
  }

  p {
    ${BodyLight}
    margin: 0 24px 16px;
  }

  button {
    padding: 12px 24px;
    border-radius: 36px;
    height: 48px;
    ${BodyHeavy}
    display: block;
  }
`;

export const Title = styled.span`
  ${H3Heavy}
  margin-bottom: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H2Heavy}
    margin-bottom: 4px;
  }
`;

export const Content = styled.div`
  ${BodyLight}
  margin: 0 24px 16px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    & > p {
      ${BodyLight}
      margin-bottom: 24px;
    }
  }
`;

export const EmptyStateButton = styled(Button)`
  & > button {
    padding: 12px 24px;
    border-radius: 36px;
    height: 48px;
    ${BodyHeavy}
  }
`;

const EmptyStateContainer = styled.div`
  text-align: center;
`;

export default EmptyStateContainer;
