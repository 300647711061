import { DealCardDefault, MediaQuery } from '@pelando/components';
import styled from 'styled-components';

export const DealCardDefaultFixedDesktop = styled(DealCardDefault)`
  display: none;
  margin-bottom: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: grid;
  }
`;

export const DealCardDefaultFixedMobile = styled(DealCardDefault)`
  display: grid;
  margin-bottom: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: none;
  }
`;
