import { DEFAULT_FEED_QUERIES_LIMIT } from '@/infra/api/offers/types/shared';
import { FeedContentDeal } from '../..';

type PaginatedDeal = {
  deal: FeedContentDeal;
  index: number;
};

type PaginatedDeals = Array<{
  pageIndex: number;
  deals: PaginatedDeal[];
}>;

export const paginateDeals = (deals: FeedContentDeal[], initialPageIndex = 0) =>
  deals.reduce((acc, deal, index) => {
    const page = Math.floor(index / DEFAULT_FEED_QUERIES_LIMIT);
    if (!acc[page]) {
      acc[page] = { pageIndex: page + initialPageIndex, deals: [] };
    }
    acc[page].deals.push({ deal, index });
    return acc;
  }, [] as PaginatedDeals);
