import React, { useCallback, type MouseEvent } from 'react';
import {
  CategoryTagProps,
  CategoryType,
  Dropdown,
  DropdownListSide,
} from '@pelando/components';

import { ChevronDown } from '@pelando/fontawesome/icons';
import { DatePeriod } from '../../../../../../domain/entities/Filter';
import FilterTime from '../FilterTimeContent';
import { useTranslation } from '../../../../../hooks/useTranslation';
import {
  ArrowIcon,
  CustomDropdownList,
  LabelContainer,
  FilterTag,
  CustomDropdownRoot,
} from './style';

export type FeedCategoryTagFilteredProps = CategoryTagProps & {
  onSelectFilter: (option: DatePeriod) => void;
  initialFilter?: DatePeriod;
};

const Label = ({ option }: { option: string }) => (
  <LabelContainer>
    <span>{option}</span>
    <ArrowIcon icon={ChevronDown} role="presentation" />
  </LabelContainer>
);

type RightContentProps = {
  active?: boolean;
  labelOption: string;
  type: CategoryType;
  filter: DatePeriod;
  onSelectFilter: (option: DatePeriod) => void;
};

const RightContent = ({
  active = false,
  labelOption,
  type,
  filter,
  onSelectFilter,
}: RightContentProps) => {
  const { t } = useTranslation('feed');

  const handleTriggerClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  return active ? (
    <CustomDropdownRoot>
      <Dropdown.TriggerButton
        onClick={handleTriggerClick}
        title={t('open-filter-button-label')}
      >
        <Label option={labelOption} />
      </Dropdown.TriggerButton>
      <Dropdown.Overlay />
      <CustomDropdownList side={DropdownListSide.RIGHT}>
        <FilterTime type={type} filterBy={filter} onFilter={onSelectFilter} />
      </CustomDropdownList>
    </CustomDropdownRoot>
  ) : (
    <Label option={labelOption} />
  );
};

export default function FeedTagCategoryFiltered({
  type,
  children,
  active,
  className,
  initialFilter = DatePeriod.TODAY,
  onClick,
  onSelectFilter,
}: FeedCategoryTagFilteredProps) {
  const { t } = useTranslation('feed');
  const title = children;
  const labelOption = t(initialFilter?.toLowerCase());

  return (
    <FilterTag
      active={active}
      onClick={onClick}
      className={className}
      type={type}
      url={t('tab-link-hottest')}
      replace
      rightContent={
        <RightContent
          active={active}
          labelOption={labelOption}
          type={type}
          filter={initialFilter}
          onSelectFilter={onSelectFilter}
        />
      }
    >
      {title}{' '}
    </FilterTag>
  );
}
