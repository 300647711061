import {
  BodyLight,
  CategoryTag,
  Dropdown,
  Icon,
  MediaQuery,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

export const FilterTag = styled(CategoryTag)`
  white-space: pre;

  span {
    ${BodyLight}
    white-space: nowrap;
  }
`;

export const ArrowIcon = styled(Icon)`
  color: rgb(${Theme.colors.Alpha});
  font-size: 24px;
`;

export const CustomDropdownList = styled(Dropdown.List)`
  && {
    padding: 0;
    width: 100%;
    border-radius: 24px 24px 0 0;
    border: 1px solid rgb(${Theme.colors.Golf});
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    && {
      border-radius: 24px;
      max-width: 375px;
    }
  }
`;

export const DropdownTriggerButton = styled(Dropdown.TriggerButton)`
  display: contents;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

export const CustomDropdownRoot = styled(Dropdown.Root)`
  && {
    margin: 0;
  }
`;
