import { OfferSortOption } from '../../../../../domain/entities/Filter';
import { ExploreTab } from '../components/ExploreFeedTabs';

export default function getExploreTabBySortOption(sortBy: OfferSortOption) {
  return (
    {
      [OfferSortOption.COMMENT]: ExploreTab.COMMENTED,
      [OfferSortOption.CREATED_AT]: ExploreTab.RECENT,
      [OfferSortOption.TEMPERATURE]: ExploreTab.HOTTEST,
      [OfferSortOption.RELEVANCE]: ExploreTab.RECENT,
    }[sortBy] || ExploreTab.RECENT
  );
}
