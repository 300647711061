import { Icon, useClickOutside } from '@pelando/components';
import {
  ChevronLeft,
  ChevronRight,
  ChevronUp,
} from '@pelando/fontawesome/icons';
import { useRef, useState } from 'react';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { ScrollDirection } from '@/presentation/hooks/useScrollDirection';
import {
  Container,
  Ellipsis,
  FloatWrapper,
  IconButton,
  Indicator,
  IndicatorWrapper,
  LeftColumn,
  LinksWrapper,
  List,
  PageLink,
  PagesList,
  PaginationWrapper,
  ShowOnlyOnLarge,
} from './styles';

type NewFeedPaginationProps = {
  /** 0 .. 9 */
  currentPageIndex: number;
  pages: string[];
  onScrollToTop?: () => void;
  onPaginate?: (pageIndex: number) => void;
  showPagination?: boolean;
  scrollDirection?: ScrollDirection;
};

export function NewFeedPagination({
  pages,
  currentPageIndex,
  onScrollToTop,
  onPaginate,
  showPagination = true,
  scrollDirection,
}: NewFeedPaginationProps) {
  const { t } = useTranslation('feed');
  const paginationRef = useRef<HTMLDivElement>(null);
  const [pagesOpened, updatePageOpened] = useState(false);

  const toggleOpen = () => updatePageOpened((current) => !current);
  const formatNumber = (num: number) => num.toString().padStart(2, '0');

  const firstPage = pages[0];
  const lastPage = pages[pages.length - 1];
  const nextPage = pages[currentPageIndex + 1];
  const previousPage = pages[currentPageIndex - 1];

  useClickOutside([paginationRef], () => updatePageOpened(false));

  const createPaginateHandler = (page: number) => () => {
    updatePageOpened(false);
    onPaginate?.(page);
  };

  const parsePageLabel = (page: number) =>
    `${t('pagination-bar-go-to-page-label')} ${page}`;

  const parsePageLink = (page: number) =>
    `${t('pagination-bar-page-abbreviation')} ${formatNumber(page)}`;

  return (
    <FloatWrapper
      data-scroll-direction={scrollDirection}
      data-show-pagination={showPagination}
    >
      <Container ref={paginationRef}>
        <LeftColumn role="menubar">
          <ShowOnlyOnLarge>
            <IconButton role="menuitem" onClick={onScrollToTop}>
              <Icon icon={ChevronUp} />
              {t('pagination-bar-go-to-top')}
            </IconButton>
          </ShowOnlyOnLarge>
          <PaginationWrapper>
            <IconButton
              role="menuitem"
              title={t('pagination-bar-previous-page-label')}
              onClick={createPaginateHandler(currentPageIndex - 1)}
              as="a"
              aria-disabled={!previousPage}
              href={previousPage}
            >
              <Icon icon={ChevronLeft} />
            </IconButton>
            <LinksWrapper>
              <PageLink
                title={t('pagination-bar-first-page-label')}
                role="menuitem"
                href={firstPage}
                aria-current={!previousPage && 'page'}
                onClick={createPaginateHandler(0)}
              >
                1
              </PageLink>
              <Ellipsis>...</Ellipsis>
              <IndicatorWrapper>
                <Indicator
                  onClick={toggleOpen}
                  aria-haspopup="true"
                  aria-expanded={pagesOpened}
                >
                  {t('pagination-bar-page-indicator')} {currentPageIndex + 1}
                </Indicator>
                <PagesList
                  data-scroll-direction={scrollDirection}
                  aria-hidden={!pagesOpened}
                >
                  <List role="menu">
                    {pages.map((link, index) => (
                      <li key={link}>
                        <a
                          href={link}
                          role="menuitem"
                          aria-current={index === currentPageIndex && 'page'}
                          title={parsePageLabel(index + 1)}
                          onClick={createPaginateHandler(index)}
                        >
                          {parsePageLink(index + 1)}
                        </a>
                      </li>
                    ))}
                  </List>
                </PagesList>
              </IndicatorWrapper>
              <Ellipsis>...</Ellipsis>
              <PageLink
                title={t('pagination-bar-last-page-label')}
                role="menuitem"
                href={lastPage}
                aria-current={!nextPage && 'page'}
                onClick={createPaginateHandler(pages.length - 1)}
              >
                {pages.length}
              </PageLink>
            </LinksWrapper>
            <IconButton
              title={t('pagination-bar-next-page-label')}
              role="menuitem"
              onClick={createPaginateHandler(currentPageIndex + 1)}
              as="a"
              aria-disabled={!nextPage}
              href={nextPage}
            >
              <Icon icon={ChevronRight} />
            </IconButton>
          </PaginationWrapper>
        </LeftColumn>
      </Container>
    </FloatWrapper>
  );
}
