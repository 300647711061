import { Deal } from '../../../../../../domain/entities/Offer';
import { getAbsoluteClientUrl, getDealUrl } from '../../../../../services/urls';

export enum SourceShareDeal {
  home = 'home',
  deal = 'deal',
}

export enum ScreenShareDeal {
  desktop = 'desktop',
  mobile = 'mobile',
}

export const getUtmShareUrl = (
  source: SourceShareDeal,
  screen: ScreenShareDeal
) => `?utm_source=share_${source}&utm_medium=${screen}&utm_campaign=${source}`;

export const shareDealMobile = (
  deal: Pick<Deal, 'id' | 'title' | 'kind' | 'status'>,
  source: SourceShareDeal
) => {
  const url =
    getAbsoluteClientUrl() +
    getDealUrl({
      id: deal.id,
      title: deal.title,
    }) +
    getUtmShareUrl(source, ScreenShareDeal.mobile);
  if (!url) return;

  if (navigator.share) {
    navigator.share({
      title: deal.title,
      url,
    });
  }
};
