import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  LaptopMobile,
  GamepadModern,
  SackDollar,
  Couch,
  Joystick,
  PersonBiking,
  Shirt,
  Books,
  HandHoldingHeart,
  FaceLaughSquint,
} from '@pelando/fontawesome/icons';

export const getCommunityIconBySlug: {
  [index in string]: IconDefinition;
} = {
  'tech-lover': LaptopMobile,
  'mundo-gamer': GamepadModern,
  'tudo-gratis': SackDollar,
  'para-meu-lar': Couch,
  'achadinhos-importados': Joystick,
  'esporte-e-vida': PersonBiking,
  'para-elas': Shirt,
  'para-eles': Shirt,
  cultura: Books,
  'para-minha-familia': HandHoldingHeart,
  'e-meme-ou-promo': FaceLaughSquint,
};
