import { parseFeedQueryParams } from '@/presentation/services/feed';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

export const useFeedPagination = () => {
  const { replace, query } = useRouter();

  const { page } = parseFeedQueryParams(query);
  const [isPaginationDelayed, setIsPaginationDelayed] = useState(true);

  const currentPageIndex = page - 1;
  const currentSlug = query.slug as string;

  const updateSearchQuery = (page: number) => {
    if (page <= 1) {
      const newQuery = { ...query };
      delete newQuery.page;

      const url = { query: newQuery };

      replace(url, undefined, {
        shallow: true,
      });

      return;
    }

    replace(
      {
        query: {
          ...query,
          page,
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const handleVisibilityChange = useCallback(
    (pageIndex: number) => {
      if (currentSlug === query.slug && pageIndex === currentPageIndex) return;

      const page = pageIndex + 1;
      updateSearchQuery(page);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsPaginationDelayed(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  return {
    currentPageIndex,
    handleVisibilityChange,
    isPaginationDelayed,
  };
};
