import { CategoryType } from '@pelando/components';
import { isFeedTabRecommendedEnabled } from '@/presentation/services/featureFlags';
import { DatePeriod } from '../../../../domain/entities/Filter';
import FeedTagCategoryFiltered from '../../../pages/public/feed/components/FeedTagCategoryFiltered';
import { SpecialFeedTab } from '../../../services/feedTabsCategories';
import {
  CategoryTagListContainer,
  CategoryTagSkeleton,
  CategoryTagStyled,
} from './styles';
import { useTranslation } from '../../../hooks/useTranslation';

export const mapSpecialFeedTabToCategoryTag = (kind: SpecialFeedTab) =>
  ({
    [SpecialFeedTab.FEATURED]: CategoryType.FEATURED,
    [SpecialFeedTab.RECENT]: CategoryType.RECENT,
    [SpecialFeedTab.COMMENTED]: CategoryType.COMMENTED,
    [SpecialFeedTab.HOTTEST]: CategoryType.HOT,
  }[kind]);

export type FeedKindTabsProps = {
  activeKind: SpecialFeedTab;
  onChangeKind: (kind: SpecialFeedTab) => void;
  currentHottestPeriod: DatePeriod;
  onChangeHottestPeriod: (period: DatePeriod) => void;
  loading?: boolean;
};

export function FeedKindTabs({
  activeKind,
  onChangeKind,
  currentHottestPeriod,
  onChangeHottestPeriod,
  loading,
}: FeedKindTabsProps) {
  const isActive = (kind: SpecialFeedTab) => kind === activeKind;
  const { t } = useTranslation('feed');

  const isTabRecommendedEnabled = isFeedTabRecommendedEnabled();

  if (loading) {
    return (
      <CategoryTagListContainer>
        <CategoryTagSkeleton animate />
        <CategoryTagSkeleton animate />
        <CategoryTagSkeleton animate />
        <CategoryTagSkeleton animate />
      </CategoryTagListContainer>
    );
  }

  return (
    <CategoryTagListContainer>
      {isTabRecommendedEnabled ? (
        <CategoryTagStyled
          type={mapSpecialFeedTabToCategoryTag(SpecialFeedTab.FEATURED)}
          active={isActive(SpecialFeedTab.FEATURED)}
          aria-pressed={isActive(SpecialFeedTab.FEATURED)}
          onClick={() => onChangeKind(SpecialFeedTab.FEATURED)}
          url={t('tab-link-featured')}
          replace
        >
          {t('tab-featured')}
        </CategoryTagStyled>
      ) : null}
      <CategoryTagStyled
        type={mapSpecialFeedTabToCategoryTag(SpecialFeedTab.RECENT)}
        active={isActive(SpecialFeedTab.RECENT)}
        aria-pressed={isActive(SpecialFeedTab.RECENT)}
        onClick={() => onChangeKind(SpecialFeedTab.RECENT)}
        url={t('tab-link-recents')}
        replace
      >
        {t('tab-recents')}
      </CategoryTagStyled>
      <CategoryTagStyled
        type={mapSpecialFeedTabToCategoryTag(SpecialFeedTab.COMMENTED)}
        active={isActive(SpecialFeedTab.COMMENTED)}
        aria-pressed={isActive(SpecialFeedTab.COMMENTED)}
        onClick={() => onChangeKind(SpecialFeedTab.COMMENTED)}
        url={t('tab-link-comments')}
        replace
      >
        {t('tab-comments')}
      </CategoryTagStyled>

      <FeedTagCategoryFiltered
        type={CategoryType.HOT}
        active={isActive(SpecialFeedTab.HOTTEST)}
        initialFilter={currentHottestPeriod}
        aria-pressed={isActive(SpecialFeedTab.HOTTEST)}
        onClick={() => onChangeKind(SpecialFeedTab.HOTTEST)}
        onSelectFilter={onChangeHottestPeriod}
        url={t('tab-link-hottest')}
        replace
      >
        {t('tab-hottest')}
      </FeedTagCategoryFiltered>
    </CategoryTagListContainer>
  );
}
