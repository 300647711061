import { Fragment, ReactNode, useEffect } from 'react';
import { useRouter } from 'next/router';
import { EnhancedNewFeedPagination } from '@/presentation/pages/public/feed/components/NewFeedPagination';
import { FIRST_FEED_PAGE, LAST_FEED_PAGE } from '@/presentation/services/feed';
import {
  ScrollDirection,
  useScrollDirection,
} from '@/presentation/hooks/useScrollDirection';
import { MediaQuery, useMediaQuery } from '@pelando/components';
import { FeedDeal, CommentedOffer } from '../../../../domain/entities/Offer';
import { RemoveVoteVariables } from '../../../../infra/api/votes/types/RemoveVote';
import { VoteVariables } from '../../../../infra/api/votes/types/Vote';
import { DealsListContent } from '../../../pages/public/feed';
import FinishedList from '../../../pages/public/feed/components/FinishedList';
import LoadMoreIntersection from '../../LoadMoreIntersection';
import FeedCard, { DealDetails } from '../FeedCard';
import generateFeedListCard from './skeleton';
import { Container, FeedCardList, ListItem } from './style';
import { LoggedUser } from '../../../../domain/entities/User';
import FixedFeedCard from '../FixedFeedCard';
import { VoteKind } from '../../../../domain/entities/Vote';
import { isFixedCardTopFeed } from '../../../services/featureFlags';
import { FeedListPageWrapper } from './components/FeedListPageWrapper';
import { useFeedPagination } from './hooks/useFeedPagination';
import { paginateDeals } from './services/paginateDeals';

export type FeedContentDeal = FeedDeal | CommentedOffer;

type FeedContentProps = {
  content: DealsListContent<FeedContentDeal>;
  categorySlug: string;
  categoryName: string;
  onVote: (variables: VoteVariables) => void;
  onRemoveVote: (variables: RemoveVoteVariables) => void;
  onFinishedListClick?: () => void;
  finishedListButtonTitle?: string;
  hideFinishedList?: boolean;
  insertContentAt?: { [at: number]: ReactNode };
  emptyState?: React.ReactNode;
  currentUser?: LoggedUser;
  hasPagination?: boolean;
  initialPage?: number;
  setIsShowingPagination?: (isShowing: boolean) => void;
  googleClientId?: string;
  cardAlertClick?: () => void;
};

const hasVisibilityObserver = (pageIndex: number, hasPagination: boolean) => {
  const lastFeedPageIndex = LAST_FEED_PAGE - 1;

  return pageIndex <= lastFeedPageIndex && hasPagination;
};

const SCROLL_THRESHOLD = 40;

export default function FeedContent({
  content,
  categorySlug,
  categoryName,
  onVote,
  onRemoveVote,
  onFinishedListClick,
  finishedListButtonTitle,
  hideFinishedList,
  insertContentAt,
  emptyState,
  currentUser,
  hasPagination = false,
  initialPage = FIRST_FEED_PAGE,
  googleClientId,
  setIsShowingPagination,
  cardAlertClick,
}: FeedContentProps) {
  const { pathname, locale } = useRouter();
  const deals = content?.deals || [];
  const hasDeals = deals.length > 0;
  const isEmpty = !content.isLoading && !hasDeals;
  const endOfData = !content.isLoading && !content.hasNextPage;
  const showFinishedList = !hideFinishedList && endOfData && !emptyState;
  const isLogged = !!currentUser;
  const hasFixedCardTopFeed = isFixedCardTopFeed();
  const isHomePage = pathname === '/[slug]';
  const isMobile = useMediaQuery(MediaQuery.SCREEN_SM);
  const showFixedCardIncentiveApp =
    isMobile && locale === 'pt-BR' && hasFixedCardTopFeed && isHomePage;
  const showFixedFeedCard =
    (!isLogged && hasFixedCardTopFeed && isHomePage) ||
    showFixedCardIncentiveApp;
  const initialPageIndex = initialPage - 1;

  // TODO: Refatorar componente para facilitar o uso da paginação
  const { handleVisibilityChange, currentPageIndex, isPaginationDelayed } =
    useFeedPagination();
  const { scrollDirection } = useScrollDirection(SCROLL_THRESHOLD);

  const paginatedDeals = paginateDeals(deals, initialPageIndex);

  const handleFinishedListClick = () => {
    content.refetch?.();
    onFinishedListClick?.();
  };

  const isPaginationVisible =
    currentPageIndex > FIRST_FEED_PAGE - 1 && !isPaginationDelayed;

  const shouldRenderPagination =
    hasPagination && !isEmpty && !isPaginationDelayed;

  const showEmptyState = isEmpty && hideFinishedList;

  const finishedListProps = {
    categoryName,
    onClick: handleFinishedListClick,
    buttonTitle: finishedListButtonTitle,
  };

  useEffect(() => {
    const isNotScrolling = scrollDirection !== ScrollDirection.Up;

    const shouldShowPagination =
      isPaginationVisible && shouldRenderPagination && isNotScrolling;

    setIsShowingPagination?.(shouldShowPagination);
  }, [
    shouldRenderPagination,
    scrollDirection,
    setIsShowingPagination,
    isPaginationVisible,
  ]);

  return (
    <>
      <Container>
        {showFixedFeedCard && (
          <FixedFeedCard
            id="fixed-card"
            voted={false}
            kind={VoteKind.INCREASE}
            googleClientId={googleClientId}
          />
        )}
        <FeedCardList>
          {paginatedDeals.map(({ deals, pageIndex }) => (
            <FeedListPageWrapper
              key={pageIndex}
              hasVisibilityObserver={hasVisibilityObserver(
                pageIndex,
                hasPagination && !isPaginationDelayed
              )}
              onVisibilityChange={handleVisibilityChange}
              pageIndex={pageIndex}
            >
              {deals.map(({ deal, index }) => (
                <Fragment key={deal.id}>
                  <ListItem>
                    <FeedCard
                      key={deal.id}
                      deal={deal as unknown as DealDetails}
                      categorySlug={categorySlug}
                      onVote={onVote}
                      onRemoveVote={onRemoveVote}
                      currentUser={currentUser}
                      position={index}
                      onClick={cardAlertClick}
                    />
                  </ListItem>
                  {insertContentAt?.[index] ? (
                    <ListItem>{insertContentAt[index]}</ListItem>
                  ) : null}
                </Fragment>
              ))}
            </FeedListPageWrapper>
          ))}
        </FeedCardList>
        {(content.isLoading || content.isPaginationLoading) &&
          generateFeedListCard(20)}
        {showEmptyState ? emptyState : null}
        {showFinishedList && <FinishedList {...finishedListProps} />}
        {content.hasNextPage && !content.isLoading && (
          <LoadMoreIntersection
            onIntersecting={content.fetchMore}
            hasNextPage={content.hasNextPage}
            loading={content.isLoading || content.isPaginationLoading}
          />
        )}
        {shouldRenderPagination && (
          <EnhancedNewFeedPagination
            currentPageIndex={currentPageIndex}
            showPagination={isPaginationVisible}
            scrollDirection={scrollDirection}
            totalPages={content.totalPages}
          />
        )}
      </Container>
    </>
  );
}
