import { useState, useEffect, useCallback } from 'react';
import { useApiCache } from '@/presentation/hooks/useApiCache';
import { acceptCookie } from '@/infra/api/cookies/endpoints';
import { isIncentiveLoginBannerEnabled } from '@/presentation/services/featureFlags';
import { getAcceptanceCookie } from '@/presentation/services/cookies/getAcceptanceCookie';

type UseFeedLoginBanner = {
  isLogged?: boolean;
};

export const useFeedLoginBanner = ({ isLogged }: UseFeedLoginBanner) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [showLoginBanner, setShowLoginBanner] = useState(false);
  const isLoginBannerEnabled = isIncentiveLoginBannerEnabled();
  const cookiesAllowed = getAcceptanceCookie();

  const { data: cookiesAllowedResponse } =
    useApiCache(acceptCookie.cache$) || {};

  const handleScroll = useCallback(() => {
    const { scrollY } = window;
    const limit = 3000;
    if (!triggerAnimation && scrollY > limit) {
      setTriggerAnimation(true);
    }
  }, [triggerAnimation]);

  const handleAnimmation = () => {
    setTriggerAnimation(true);
  };

  useEffect(() => {
    if ((cookiesAllowed || cookiesAllowedResponse) && isLoginBannerEnabled)
      setShowLoginBanner(!isLogged);
  }, [isLogged, cookiesAllowed, cookiesAllowedResponse, isLoginBannerEnabled]);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return {
    handleScroll,
    handleAnimmation,
    triggerAnimation,
    showLoginBanner,
  };
};
