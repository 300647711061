import {
  Colors,
  H1Heavy,
  H2Heavy,
  MediaQuery,
  SmallLight,
  Theme,
} from '@pelando/components';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 16px 0 12px;
  position: relative;
  background-color: rgb(${({ theme }) => theme.colors.Juliet});

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: 24px 40px;
    border-bottom: 1px solid rgb(${Theme.colors.Golf});
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    height: 72px;
    width: 100%;
    background: linear-gradient(
      360deg,
      rgba(${Theme.colors.Juliet}, 0) 0%,
      rgb(${Theme.colors.Lima}) 100%
    );

    @media ${MediaQuery.SCREEN_MD_UP} {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media ${MediaQuery.SCREEN_MD_UP} {
    flex-direction: row;
    align-items: center;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin: 46px 0 16px;
  background-color: rgb(${Theme.colors.Lima});

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin: 0 16px 0;
  }
`;

export const TextContainer = styled.div`
  display: contents;

  @media ${MediaQuery.SCREEN_MD_UP} {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  ${H2Heavy}

  @media ${MediaQuery.SCREEN_MD_UP} {
    ${H1Heavy}
  }
`;

export const Description = styled.h2`
  ${SmallLight}
  color: rgb(${Colors.Gray});
`;
