export const enum DealCardComponentNames {
  SeeDealButton = 'deal_card_see_deal_button',
  CommentsIconButton = 'deal_card_comments_icon_button',
  Image = 'deal_card_image',
  Title = 'deal_card_title',
  HeaderComment = 'deal_card_header_comment',
  CardContent = 'deal_card_content',
  FooterTagComment = 'deal_card_footer_tag_comment',
  SaveButton = 'deal_card_save_button',
  HotDeal = 'hot_deal_card',
  NoResultSearchCard = 'deal_card_no_results_search',
}

export const enum StorePageComponentNames {
  CouponModalSeeDealLink = 'store_page_coupon_modal_see_deal_link',
}

export const enum ProductPageComponentNames {
  SecondaryDealCard = 'product_page_card',
}

export const enum SearchPageComponentNames {
  StoreSmallCouponCard = 'search_page_store_small_coupon_card',
}
