import {
  shouldActivateFeedRecombee,
  shouldActivateFeedRecombeeLoggedOut,
} from '../../presentation/services/featureFlags';
import { getProbablyLoggedCookie } from '../auth/tokenManagement';

export const getRecombeeActivatedEventParam = () => {
  if (getProbablyLoggedCookie()) {
    return shouldActivateFeedRecombee();
  }
  return shouldActivateFeedRecombeeLoggedOut();
};
