import { Caption, MediaQuery, TextSkeleton } from '@pelando/components';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../../components/Header/styles';

export const FEED_TABS_Z_INDEX = 3;

export const Container = styled.div`
  width: 100%;
`;

export const TabOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: sticky;
  top: ${HEADER_HEIGHT - 1}px;
  z-index: ${FEED_TABS_Z_INDEX};
  background: rgb(${({ theme }) => theme.colors.Kilo});
  padding: 16px 0;

  @media ${MediaQuery.SCREEN_LG_UP} {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &[data-is-community-feed='true'] {
    background-color: rgb(${({ theme }) => theme.colors.Juliet});

    @media ${MediaQuery.SCREEN_MD_UP} {
      background-color: rgb(${({ theme }) => theme.colors.Kilo});
    }
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  @media ${MediaQuery.SCREEN_LG_UP} {
    margin-top: 0px;
    justify-content: unset;
  }
`;

export const SwitchText = styled.span`
  ${Caption}
  color: rgb(${({ theme }) => theme.colors.Alpha})
`;

export const CategoryTagListContainer = styled.div`
  display: flex;
  overflow: auto;
  max-height: 40px;
  gap: 4px;

  & > span {
    &[aria-selected='true'] {
      margin: 0 4px;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const SwitchSkeleton = styled(TextSkeleton)`
  margin-left: 8px;
  width: 36px;
  height: 20px;
  border-radius: 16px;
`;

export const SwitchTextSkeleton = styled(TextSkeleton)`
  width: 114px;
  height: 20px;
`;
