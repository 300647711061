import { StampType } from '@pelando/components';
import { Deal } from '../../../domain/entities/Offer';

type DealPriceValues = Partial<
  Pick<Deal, 'discountFixed' | 'discountPercentage' | 'price' | 'freeShipping'>
>;

export default function getStampType({
  discountFixed,
  discountPercentage,
  freeShipping,
  price,
}: DealPriceValues) {
  if (discountFixed) {
    return StampType.DISCOUNT_FIXED;
  }
  if (discountPercentage) {
    return StampType.DISCOUNT_PERCENTAGE;
  }
  if (price) {
    return StampType.PRICE;
  }
  if (freeShipping) {
    return StampType.FREE_SHIPPING;
  }
  return StampType.FREE;
}
