import { DAY_TO_MS, HOUR_TO_MS, MINUTE_TO_MS, TIME_DAYS } from './consts';
import getDaysAgo from './getDaysAgo';
import { getShortMonth } from './getShortMonth';

export const getTimeAgoString = (pastDate: Date, language: string) => {
  const publishedTime = pastDate.getTime();
  const currentTime = Date.now();
  const timeDistance = currentTime - publishedTime;
  const yesterday = getDaysAgo(1);

  if (timeDistance < MINUTE_TO_MS) {
    return TIME_DAYS[language].now;
  }
  if (timeDistance < HOUR_TO_MS) {
    return `${Math.min(Math.round(timeDistance / MINUTE_TO_MS), 59)} min`;
  }
  if (timeDistance < DAY_TO_MS) {
    return `${Math.min(Math.round(timeDistance / HOUR_TO_MS), 23)} h`;
  }
  if (pastDate.toDateString() === yesterday.toDateString()) {
    return TIME_DAYS[language]?.yesterday;
  }

  const monthShort = getShortMonth(pastDate, language);

  if (pastDate.getFullYear() === new Date(Date.now()).getFullYear()) {
    const dayWithLeadingZeros = `0${pastDate.getDate()}`.slice(-2);
    return language === 'pt-BR'
      ? `${dayWithLeadingZeros} ${monthShort}`
      : `${monthShort} ${dayWithLeadingZeros} `;
  }
  return `${monthShort} ${pastDate.getFullYear()}`;
};
