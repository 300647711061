import React, { type MouseEvent, useMemo, useCallback } from 'react';

import { CategoryType, useDropdownContext } from '@pelando/components';

import { useTranslation } from '@/presentation/hooks/useTranslation';
import { Xmark } from '@pelando/fontawesome/icons';
import { filterOptionLabelByOption } from './const';
import {
  CloseIcon,
  FilterItem,
  FilterTimeContainer,
  FilterTimeContent,
  FilterTimeHeader,
} from './style';
import { DatePeriod } from '../../../../../../domain/entities/Filter';

export type FilterTimeProps = {
  onFilter: (filterBy: DatePeriod) => void;
  filterBy?: DatePeriod;
  type: CategoryType;
};

function FilterTime({ onFilter, filterBy }: FilterTimeProps) {
  const { t } = useTranslation('feed');
  const { closeDropdown } = useDropdownContext();

  const filterOptions = useMemo(() => Object.values(DatePeriod), []);

  const handleCloseClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      closeDropdown();
    },
    [closeDropdown]
  );

  const handleContainerClick = useCallback((event: MouseEvent) => {
    event.stopPropagation();
  }, []);

  const handleFilterItemClick = useCallback(
    (option: DatePeriod, event: MouseEvent) => {
      event.stopPropagation();

      onFilter(option);
      closeDropdown();
    },
    [onFilter, closeDropdown]
  );

  return (
    <FilterTimeContainer onClick={handleContainerClick}>
      <FilterTimeHeader>
        <span>{t('filter-time')}</span>
        <CloseIcon
          icon={Xmark}
          onClick={handleCloseClick}
          role="button"
          aria-label={t('filter-time-close-icon-aria-label')}
        />
      </FilterTimeHeader>
      <FilterTimeContent>
        <span>{t('filter-time-period')}</span>
        {filterOptions.map((option) => (
          <FilterItem
            key={option}
            aria-selected={option === filterBy}
            onClick={(event) => handleFilterItemClick(option, event)}
            disabled={option === filterBy}
          >
            {t(filterOptionLabelByOption[option])}
          </FilterItem>
        ))}
      </FilterTimeContent>
    </FilterTimeContainer>
  );
}

export default FilterTime;
