import { ReactNode } from 'react';
import { PageVisibilityObserver } from '../PageVisibilityObserver';

type Props = {
  children: ReactNode;
  hasVisibilityObserver: boolean;
  onVisibilityChange: (pageIndex: number) => void;
  pageIndex: number;
};

export const FeedListPageWrapper = ({
  children,
  hasVisibilityObserver,
  onVisibilityChange,
  pageIndex,
}: Props) => {
  if (!hasVisibilityObserver) return children;

  return (
    <PageVisibilityObserver
      pageIndex={pageIndex}
      onVisibilityChange={onVisibilityChange}
    >
      {children}
    </PageVisibilityObserver>
  );
};
