import { HeaderVerifiedAuthor } from '@pelando/components';
import { DealUser } from '../../../domain/entities/Offer';

export const getDealCardVerifiedAuthorHeader = (author?: DealUser) =>
  !!author?.isVerified && (
    <HeaderVerifiedAuthor
      authorNickname={author.nickname}
      authorImage={author.image?.url}
    />
  );
