import { Langs } from '@/locales';

export default function getPriceString(price: number) {
  const finalPrice = price > 99.99 ? Math.floor(price) : price;
  const isFloat = finalPrice % 1 !== 0;

  return isFloat
    ? finalPrice.toFixed(2).replace('.', ',')
    : finalPrice.toString();
}

export function getCurrencySymbolByLocale(locale: Langs) {
  const currency = (
    {
      'pt-BR': 'BRL',
      'en-US': 'USD',
    } as Record<Langs, string>
  )[locale];

  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}
