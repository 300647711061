import { Deal } from '../../../domain/entities/Offer';
import getPriceString from './getPriceString';

type DealPriceValues = Partial<
  Pick<Deal, 'discountFixed' | 'discountPercentage' | 'price'>
>;

export default function getDealValue({
  discountFixed,
  discountPercentage,
  price,
}: DealPriceValues) {
  return getPriceString(discountFixed || discountPercentage || price || 0);
}
