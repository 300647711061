import { isNewFeedPaginationEnabled } from '../featureFlags';

type FeedQuery = Record<string, string | undefined | string[]>;

export const LAST_FEED_PAGE = 10;

export const FIRST_FEED_PAGE = 1;

export const parseFeedQueryParams = (query: FeedQuery) => {
  let page = FIRST_FEED_PAGE;

  if (query.page && typeof query.page === 'string') {
    const parsedPage = parseInt(query.page, 10);
    const isValidPage = !Number.isNaN(parsedPage);

    if (isValidPage) {
      page = parsedPage;
    }
  }

  return {
    page,
  };
};

export const isValidFeedPage = (page: number) => {
  const isNewPaginationEnabled = isNewFeedPaginationEnabled();

  if (!isNewPaginationEnabled) {
    return true;
  }

  return page > 0 && page <= LAST_FEED_PAGE;
};
