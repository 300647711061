import styled, { css } from 'styled-components';
import { maximumGridStyle } from '@/presentation/styles/global';
import {
  CaptionHeavy,
  CaptionLight,
  Colors,
  MediaQuery,
  Theme,
} from '@pelando/components';
import { ScrollDirection } from '@/presentation/hooks/useScrollDirection';
import { FEED_TABS_Z_INDEX } from '../SpecialFeedTabs/style';

export const FEED_PAGINATION_HEIGHT = 56;

export const FloatWrapper = styled.div`
  width: 100vw;
  position: fixed;
  left: 0;
  bottom: 64px; /* bottom navigation height */
  z-index: ${FEED_TABS_Z_INDEX};
  background-color: rgb(${Theme.colors.Juliet});
  border-top: 1px solid rgb(${Theme.colors.FoxTrot});

  @media ${MediaQuery.SCREEN_LG_UP} {
    bottom: 0;
  }

  transition: transform 0.3s ease-in-out;

  &[data-scroll-direction=${ScrollDirection.Down}], &[data-show-pagination='true'] {
    transform: translate3d(0, 0, 0);
  }

  &[data-scroll-direction=${ScrollDirection.Up}], &[data-show-pagination='false'] {
    transform: translate3d(0, 100%, 0);
  }
`;

export const Container = styled.div`
  ${maximumGridStyle}
  display: grid;
  grid-template-columns: 1fr;
  padding: 12px 0;
  background-color: rgb(${Theme.colors.Juliet});

  @media ${MediaQuery.SCREEN_XL} {
    grid-template-columns: minmax(auto, 1fr) minmax(300px, auto); /* sidebar width */
    gap: 16px;
  }
`;

export const LeftColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media ${MediaQuery.SCREEN_LG_UP} {
    grid-template-columns: 1fr 2fr 1fr;
    gap: 24px;
  }
`;

const inactiveStyle = css`
  opacity: 0.5;
  filter: grayscale(100%);
  pointer-events: none;
  cursor: default;
`;

export const IconButton = styled.div`
  ${CaptionHeavy}
  display: flex;
  align-items: center;
  gap: 12px;
  color: rgb(${Theme.colors.Brand});
  cursor: pointer;
  white-space: nowrap;

  &[aria-disabled='true'] {
    ${inactiveStyle};
  }

  i {
    font-size: 18px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1px solid rgb(${Theme.colors.Golf});
  }
`;

export const PaginationWrapper = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 0 16px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    padding: unset;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media ${MediaQuery.SCREEN_MD_UP} {
    width: auto;
    justify-content: unset;
    gap: 36px;
  }
`;

export const PageLink = styled.a`
  text-decoration: none;
  ${CaptionHeavy}

  &[aria-current="page"] {
    ${inactiveStyle};
  }
`;

export const Ellipsis = styled.div`
  color: rgb(${Theme.colors.Brand});
  cursor: default;
  ${CaptionHeavy}
`;

export const Indicator = styled.button`
  ${CaptionHeavy}
  background-color: unset;
  white-space: nowrap;
  cursor: pointer;
  min-width: 98px;
`;

export const IndicatorWrapper = styled.div`
  ${CaptionHeavy}
  background-color: unset;
  position: relative;
`;

export const List = styled.ul`
  list-style-type: none;
  text-align: center;
  overflow: auto;
  max-height: 248px;
  scrollbar-color: rgb(${Theme.colors.Brand}) transparent;
  scrollbar-width: thin;
  scrollbar-gutter: stable both-edges;
  overscroll-behavior: contain;
  li {
    margin-top: 16px;
    a {
      cursor: pointer;
      ${CaptionLight}
      text-decoration: none;
      color: rgb(${Theme.colors.Alpha});

      &[aria-current='page'] {
        ${inactiveStyle};
      }
    }
    &:last-of-type {
      margin-bottom: 16px;
    }
  }
`;

export const PagesList = styled.div`
  position: absolute;
  bottom: 38px;
  z-index: -1;
  width: 100%;
  overflow: hidden;
  background-color: rgb(${Theme.colors.Papa});
  border: 1px solid rgb(${Colors.Honey});
  border-bottom: unset;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  transition: transform 0.3s ease-in-out;

  &[data-scroll-direction=${ScrollDirection.Down}], &[aria-hidden='false'] {
    transform: translate3d(0, 0, 0);
  }

  &[data-scroll-direction=${ScrollDirection.Up}], &[aria-hidden='true'] {
    transform: translate3d(0, 100%, 0);
  }
`;

export const ShowOnlyOnLarge = styled.div`
  display: none;
  @media ${MediaQuery.SCREEN_LG_UP} {
    display: contents;
  }
`;
