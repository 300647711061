import { useEffect, useState } from 'react';

export const enum ScrollDirection {
  Up = 'up',
  Down = 'down',
}

const SCROLL_THRESHOLD = 10;

export const useScrollDirection = (threshold = SCROLL_THRESHOLD) => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>();

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const direction =
        currentScrollY > lastScrollY
          ? ScrollDirection.Down
          : ScrollDirection.Up;
      const scrollDifference = Math.abs(currentScrollY - lastScrollY);
      const hadDirectionChange = direction !== scrollDirection;
      const isScrollSignificant = scrollDifference > threshold;

      if (hadDirectionChange && isScrollSignificant) {
        setScrollDirection(direction);
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollDirection, threshold]);

  return { scrollDirection };
};
