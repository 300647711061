import { Caption, CategoryTag, MediaQuery } from '@pelando/components';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../../../components/Header/styles';

export const Container = styled.div`
  width: 100%;
`;

export const TabOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: sticky;
  top: ${HEADER_HEIGHT - 1}px;
  z-index: 3;
  background: rgb(${({ theme }) => theme.colors.Kilo});
  padding: 16px 0;

  @media ${MediaQuery.SCREEN_LG_UP} {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &[data-is-community-feed='true'] {
    background-color: rgb(${({ theme }) => theme.colors.Juliet});

    @media ${MediaQuery.SCREEN_MD_UP} {
      background-color: rgb(${({ theme }) => theme.colors.Kilo});
    }
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  @media ${MediaQuery.SCREEN_LG_UP} {
    margin-top: 0px;
    justify-content: unset;
  }
`;

export const SwitchText = styled.span`
  ${Caption}
  color: rgb(${({ theme }) => theme.colors.Alpha});
`;

export const CategoryButton = styled(CategoryTag)`
  white-space: nowrap;
`;
export const CategoryTagListContainer = styled.div`
  display: flex;
  overflow: auto;
  max-height: 40px;
  gap: 4px;

  & > span {
    &[aria-selected='true'] {
      margin: 0 4px;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;
