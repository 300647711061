import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { CookiesActionType } from './actionTypes';
import { AcceptCookieMutationResponse } from './types/AcceptCookie';
import { ACCEPT_COOKIE_MUTATION } from './mutations/acceptCookie';

export const acceptCookie = createNewEndpoint<
  AcceptCookieMutationResponse,
  void,
  boolean
>({
  id: CookiesActionType.PostAcceptCookies,
  query: ACCEPT_COOKIE_MUTATION,
  method: GraphQlMethods.Post,
  transform: (response) => response.public.agreeWithCookiesTerms,
});
