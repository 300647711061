import styled, { css } from 'styled-components';
import {
  BodyLight,
  Icon,
  MediaQuery,
  Skeleton,
  SmallStoreCard,
} from '@pelando/components';

export const StoreCard = styled(SmallStoreCard)`
  padding: 10px 16px;
  height: 40px;
  min-width: min-content;
`;

export const StoreCardSkeleton = styled(Skeleton)`
  padding: 10px 16px;
  border-radius: 12px;
  min-width: 124px;
  height: 40px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 8px;
  }
`;

export const Title = styled.h1`
  ${BodyLight}
  margin-bottom: 8px;

  @media ${MediaQuery.SCREEN_MD_UP} {
    margin-bottom: 12px;
  }
`;

export const CarouselNavigation = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  column-gap: 4px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${MediaQuery.SCREEN_LG_UP} {
    overflow-x: scroll;
  }
`;

const containerFadeDefaultStyle = css`
  content: ' ';
  z-index: 1;
  width: 16px;
  position: absolute;
  height: 100%;
  bottom: 0;

  @media ${MediaQuery.SCREEN_MD_UP} {
    width: 60px;
  }
`;

const fadeStyle = (side: 'left' | 'right', showFade: boolean) => {
  if (!showFade) {
    return null;
  }
  return css`
    &::${side === 'right' ? 'after' : 'before'} {
      ${containerFadeDefaultStyle}
      ${side}: 0;
      background: linear-gradient(
        to ${side},
        rgba(${({ theme }) => theme.colors.Kilo}, 0),
        rgba(${({ theme }) => theme.colors.Kilo}, 100%)
      );
    }
  `;
};

export const CarouselContainer = styled.div`
  width: 100%;
  position: relative;

  &[data-forward-enabled='true'] {
    ${fadeStyle('right', true)};
  }

  &[data-back-enabled='true'] {
    ${fadeStyle('left', true)};
  }
`;

const CarouselArrowStyles = css`
  display: none;

  &:disabled {
    display: none;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translate(0, -50%);

    display: flex;
    justify-content: center;
    align-items: center;

    background: rgb(${({ theme }) => theme.colors.Juliet});
    padding: 0px 6px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid rgb(${({ theme }) => theme.colors.Golf});
    cursor: pointer;
  }
`;

export const CarouselArrowLeft = styled.button`
  ${CarouselArrowStyles}
  left: 0;
`;

export const CarouselArrowRight = styled.button`
  ${CarouselArrowStyles}
  right: 0;
`;

export const CarouselArrowIcon = styled(Icon)`
  font-size: 24px;
  color: rgb(${({ theme }) => theme.colors.Alpha});
`;
