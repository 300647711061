const LOCALSTORAGE_ONBOARDING_CONTEXT_ALERT_OTHER_FUNCTION_STAGE =
  'ONBOARDING_CONTEXT_ALERT_OTHER_FUNCTION_STAGE';

export function addOnboardingContextAlertOtherFunctionStageLocalStorage(
  step?: string
) {
  return window.localStorage.setItem(
    LOCALSTORAGE_ONBOARDING_CONTEXT_ALERT_OTHER_FUNCTION_STAGE,
    step || '0'
  );
}

export function removeOnboardingContextAlertOtherFunctionFromLocalStorage() {
  window.localStorage.removeItem(
    LOCALSTORAGE_ONBOARDING_CONTEXT_ALERT_OTHER_FUNCTION_STAGE
  );
}

export function getOnboardingContextAlertOtherFunctionLocalStorage() {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(
      LOCALSTORAGE_ONBOARDING_CONTEXT_ALERT_OTHER_FUNCTION_STAGE
    );
  }
  return undefined;
}

const LOCALSTORAGE_ONBOARDING_CONTEXT_CHECK_VIDEO =
  'ONBOARDING_CONTEXT_CHECK_VIDEO';

export function addOnboardingContextCheckVideo() {
  return window.localStorage.setItem(
    LOCALSTORAGE_ONBOARDING_CONTEXT_CHECK_VIDEO,
    'true'
  );
}

export function removeOnboardingContextCheckVideo() {
  window.localStorage.removeItem(LOCALSTORAGE_ONBOARDING_CONTEXT_CHECK_VIDEO);
}

export function getOnboardingContextCheckVideo() {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(
      LOCALSTORAGE_ONBOARDING_CONTEXT_CHECK_VIDEO
    );
  }
  return undefined;
}
