import { CategoryType } from '@pelando/components';
import FeedTagCategoryFiltered from '@/presentation/pages/public/feed/components/FeedTagCategoryFiltered';
import { ChangeFilterOptions } from '@/presentation/pages/public/feed';
import { ExploreTab } from '@/presentation/pages/public/feed/components/ExploreFeedTabs';
import { DatePeriod, OfferSortOption } from '@/domain/entities/Filter';
import { CategoryTagSkeleton } from '../FeedKindTabs/styles';
import { CategoryButton, CategoryTagListContainer } from './styles';
import { useTranslation } from '../../../hooks/useTranslation';

export type OrderByTabsProps = {
  activeOrder: OfferSortOption;
  currentHottestPeriod?: DatePeriod;
  onChangeOrder: (order: OfferSortOption) => void;
  onChangeFilter: (
    filter: ChangeFilterOptions,
    exploreTab?: ExploreTab
  ) => void;
  loading?: boolean;
  categorySlug: string;
};

export const mapOfferSortOptionToCategoryTag = (
  order: Exclude<OfferSortOption, OfferSortOption.RELEVANCE>
) =>
  ({
    [OfferSortOption.CREATED_AT]: CategoryType.RECENT,
    [OfferSortOption.COMMENT]: CategoryType.COMMENTED,
    [OfferSortOption.TEMPERATURE]: CategoryType.HOT,
  }[order]);

export function OrderByTabs({
  activeOrder,
  onChangeOrder,
  loading,
  categorySlug,
  onChangeFilter,
  currentHottestPeriod,
}: OrderByTabsProps) {
  const { t } = useTranslation('feed');
  const isActive = (order: OfferSortOption) => order === activeOrder;

  if (loading) {
    return (
      <CategoryTagListContainer>
        <CategoryTagSkeleton animate />
        <CategoryTagSkeleton animate />
        <CategoryTagSkeleton animate />
      </CategoryTagListContainer>
    );
  }

  return (
    <CategoryTagListContainer>
      <CategoryButton
        type={mapOfferSortOptionToCategoryTag(OfferSortOption.CREATED_AT)}
        active={isActive(OfferSortOption.CREATED_AT)}
        aria-pressed={isActive(OfferSortOption.CREATED_AT)}
        onClick={(event) => {
          event.preventDefault();
          onChangeOrder(OfferSortOption.CREATED_AT);
        }}
        url={categorySlug}
      >
        {t('tab-created-at')}
      </CategoryButton>
      <CategoryButton
        type={mapOfferSortOptionToCategoryTag(OfferSortOption.COMMENT)}
        active={isActive(OfferSortOption.COMMENT)}
        aria-pressed={isActive(OfferSortOption.COMMENT)}
        onClick={(event) => {
          event.preventDefault();
          onChangeOrder(OfferSortOption.COMMENT);
        }}
        url={categorySlug}
      >
        {t('tab-comment')}
      </CategoryButton>
      <FeedTagCategoryFiltered
        type={mapOfferSortOptionToCategoryTag(OfferSortOption.TEMPERATURE)}
        active={isActive(OfferSortOption.TEMPERATURE)}
        aria-pressed={isActive(OfferSortOption.TEMPERATURE)}
        onClick={() => onChangeOrder(OfferSortOption.TEMPERATURE)}
        initialFilter={currentHottestPeriod}
        url={categorySlug}
        onSelectFilter={(period) =>
          onChangeFilter({ hottestFeedPeriod: period })
        }
      >
        {t('tab-hot')}
      </FeedTagCategoryFiltered>
    </CategoryTagListContainer>
  );
}
