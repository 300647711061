import { CategoryTag, TextSkeleton } from '@pelando/components';
import styled from 'styled-components';

export const CategoryTagListContainer = styled.div`
  display: flex;
  overflow: auto;
  max-height: 40px;
  gap: 4px;

  > span {
    &[aria-selected='true'] {
      margin: 0 4px;
      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const CategoryTagStyled = styled(CategoryTag)`
  white-space: nowrap;
`;

export const CategoryTagSkeleton = styled(TextSkeleton)`
  border-radius: 12px;
  min-width: 140px;
  height: 40px;
`;
