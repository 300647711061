import React, { ReactNode } from 'react';
import EmptyStateContainer, {
  Content,
  EmptyStateButton,
  IconWrapper,
  Title,
} from './style';

type EmptyState = {
  title: string;
  content?: ReactNode;
  onClick?: () => void;
  buttonTitle?: string;
  className?: string;
  icon?: JSX.Element;
};

function EmptyState({
  title,
  content,
  onClick,
  buttonTitle,
  className,
  icon,
}: EmptyState) {
  return (
    <EmptyStateContainer className={className}>
      <IconWrapper>{icon}</IconWrapper>
      <Title>{title}</Title>
      {content && <Content>{content}</Content>}
      {buttonTitle && (
        <EmptyStateButton onClick={onClick}>{buttonTitle}</EmptyStateButton>
      )}
    </EmptyStateContainer>
  );
}

export default EmptyState;
