import { EventCategory, GeneralDealEvents } from '@/domain/events/analytics';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import { getRecombeeActivatedEventParam } from '@/infra/events/recombee';
import { getDupelandoOriginFlag } from '../dupelando';
import {
  DealCardComponentNames,
  StorePageComponentNames,
  ProductPageComponentNames,
  SearchPageComponentNames,
} from './componentNames';

export const emitGoToDealDetailsEvent = (
  componentName:
    | DealCardComponentNames
    | StorePageComponentNames
    | ProductPageComponentNames
    | SearchPageComponentNames,
  dealId?: string,
  position?: number
) => {
  emitTrackingEvent({
    category: EventCategory.GeneralDeal,
    name: GeneralDealEvents.ClickToGoToDealDetails,
    extra: {
      deal_id: dealId,
      component_name: componentName,
      recombeeActived: getRecombeeActivatedEventParam(),
      index_item: position !== undefined ? position + 1 : undefined,
      came_from_dpl: getDupelandoOriginFlag(),
    },
  });
};
