import { MediaQuery, useMediaQuery } from '@pelando/components';
import { useEffect, useMemo, useState } from 'react';
import { SlotCode } from '../../../../../components/AdPlacement/constants';
import { FeedListAdCard } from '../../style';

export function useFeedCardsAds() {
  const isMobile = useMediaQuery(MediaQuery.SCREEN_SM);
  const [adsAfterIndexCount, setAdsAfterIndexCount] = useState(8);

  useEffect(() => {
    if (isMobile) setAdsAfterIndexCount(5);
    else setAdsAfterIndexCount(8);
  }, [isMobile]);

  const feedAds = useMemo(
    () =>
      [
        SlotCode.FEED_HORIZONTAL01,
        SlotCode.FEED_HORIZONTAL02,
        SlotCode.FEED_HORIZONTAL03,
        SlotCode.FEED_HORIZONTAL04,
        SlotCode.FEED_HORIZONTAL05,
      ].reduce(
        (acc, slotCode, index) => ({
          ...acc,
          [(index + 1) * adsAfterIndexCount - 1]: (
            <FeedListAdCard slotCode={slotCode} data-testid={slotCode} />
          ),
        }),
        {}
      ),
    [adsAfterIndexCount]
  );

  return feedAds;
}
