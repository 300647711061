import {
  DealStatus,
  MediaQuery,
  StampType,
  useMediaQuery,
} from '@pelando/components';
import { useRouter } from 'next/router';
import { URL_APP_CARD_FIXED, getLoginUrl } from '@/presentation/services/urls';
import { getImagePath } from '@/presentation/services/image';
import { VoteKind } from '../../../../domain/entities/Vote';
import { EventCategory, FeedEvents } from '../../../../domain/events/analytics';
import { emitTrackingEvent } from '../../../../infra/analytics/emitTrackingEvent';
import { useTranslation } from '../../../hooks/useTranslation';
import {
  DealCardDefaultFixedDesktop,
  DealCardDefaultFixedMobile,
} from './style';
import { AuthKind } from '../../Authentication/types';
import Authentication from '../../Authentication';
import { useAuthModal } from '../../Authentication/hooks/useAuthModal';

const IMAGE_PATH = 'card-fixed-image.png';
const IMAGE_PATH_MOBILE = 'card-fixed-image-mobile.png';

export type Vote = {
  id: string;
  voted: boolean;
  kind: VoteKind;
  googleClientId?: string;
};

const FixedFeedCard = ({ id, voted, kind, googleClientId }: Vote) => {
  const { t } = useTranslation('feed');
  const { push, locale } = useRouter();
  const { showModal, closeModal } = useAuthModal();
  const isMobile = useMediaQuery(MediaQuery.SCREEN_SM);
  const appIncentive = isMobile && locale === 'pt-BR';
  const loginUrl = getLoginUrl(locale);
  const urlOnClick = locale === 'pt-BR' ? URL_APP_CARD_FIXED : loginUrl;

  const showAuthModal = () => {
    showModal(
      <Authentication
        kind={AuthKind.SIGNUP}
        onFinishSuccessfully={() => {
          push('/');
        }}
        onCloseModal={closeModal}
        googleClientId={googleClientId}
      />
    );
  };

  const voteInfo = {
    id,
    voted,
    kind,
  };

  const handleActionOnClick = () =>
    appIncentive ? push(URL_APP_CARD_FIXED) : showAuthModal();

  const handleOnClickFixedCard = () => {
    handleActionOnClick();
    emitTrackingEvent({
      category: EventCategory.Feed,
      name: FeedEvents.ClickFixedCard,
      extra: { interaction: true },
    });
  };

  return (
    <>
      <DealCardDefaultFixedDesktop
        id="fixed-card"
        title={t('fixed-feed-card-title')}
        dealUrl={loginUrl}
        status={DealStatus.ACTIVE}
        timestamp="1h"
        stampType={StampType.FREE}
        storeSlug={loginUrl}
        image={getImagePath(locale || '', IMAGE_PATH)}
        storeText={t('fixed-feed-card-text')}
        storeName={t('fixed-feed-card-store-name')}
        commentCount={0}
        temperature={1298}
        commentsDealUrl=""
        isFixedCard
        fixedCardButtonText={t('fixed-feed-card-button-text')}
        voteInfo={voteInfo}
        dispatch={() => handleOnClickFixedCard()}
        locale={locale || ''}
      />
      <DealCardDefaultFixedMobile
        id="fixed-card"
        title={t('fixed-feed-card-title-mobile')}
        dealUrl={URL_APP_CARD_FIXED}
        status={DealStatus.ACTIVE}
        timestamp="1h"
        stampType={StampType.FREE}
        storeSlug={urlOnClick}
        image={getImagePath(locale || '', IMAGE_PATH_MOBILE)}
        storeText={t('fixed-feed-card-text-mobile')}
        storeName={t('fixed-feed-card-store-name')}
        commentCount={0}
        temperature={1298}
        commentsDealUrl=""
        isFixedCard
        fixedCardButtonText={t('fixed-feed-card-button-text-mobile')}
        voteInfo={voteInfo}
        dispatch={() => handleOnClickFixedCard()}
        locale={locale || ''}
      />
    </>
  );
};

export default FixedFeedCard;
