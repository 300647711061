import {
  AuthKind,
  AuthProvider,
  LoginComponentSourceName,
} from '@/presentation/components/Authentication/types';
import { AgreementType } from '@/domain/entities/Agreement';
import ExternalAgreementLink from '@/presentation/components/Authentication/components/ExternalAgreementLink';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { Envelope } from '@pelando/fontawesome/icons';
import { useAuthModal } from '@/presentation/components/Authentication/hooks/useAuthModal';
import dynamic from 'next/dynamic';
import GoogleLoginBtn from '@/presentation/components/GoogleLoginButton';
import { useRouter } from 'next/router';
import {
  Banner,
  BannerButton,
  BannerHeader,
  ButtonsGroup,
  Container,
  Content,
  EmailButton,
  HeaderContent,
  LoginSection,
  SocialButton,
  SubTitle,
  TermsOfUse,
  Title,
} from './style';

const AuthModal = dynamic(
  () =>
    import('@/presentation/components/Authentication').then(
      (mod) => mod.default
    ),
  { ssr: false }
);

type FeedLoginBanner = {
  triggerAnimation: boolean;
  handleAnimmation: () => void;
  googleClientId?: string;
  isShowingPagination?: boolean;
};

function FeedLoginBanner({
  triggerAnimation,
  handleAnimmation,
  googleClientId,
  isShowingPagination,
}: FeedLoginBanner) {
  const { t } = useTranslation('feed', 'login');
  const { asPath } = useRouter();
  const { showModal, closeModal } = useAuthModal();
  const mapAgreementTypeToText = (agreementType: AgreementType) =>
    ((
      {
        [AgreementType.PRIVACY_POLICY]: t('login-privacy-policy'),
        [AgreementType.TERMS_OF_SERVICE]: t('login-terms-of-service'),
      } as Record<AgreementType, string>
    )[agreementType]);

  return (
    <Container data-is-showing-pagination={isShowingPagination}>
      <Content className="maximumGrid">
        <Banner data-expand={triggerAnimation}>
          <BannerHeader>
            <HeaderContent data-expand={triggerAnimation}>
              <Title>{t('fixed-feed-banner-title-text')}</Title>
              <SubTitle>{t('fixed-feed-banner-subtitle-text')}</SubTitle>
            </HeaderContent>
            <BannerButton
              data-visible={!triggerAnimation}
              light
              responsive
              onClick={handleAnimmation}
            >
              {t('fixed-feed-banner-button-text')}
            </BannerButton>
          </BannerHeader>
          <LoginSection>
            <ButtonsGroup>
              <EmailButton
                onClick={() => {
                  showModal(
                    <AuthModal
                      kind={AuthKind.LOGIN}
                      onCloseModal={closeModal}
                      componentSourceName={
                        LoginComponentSourceName.homeAuthBanner
                      }
                    />
                  );
                }}
                icon={Envelope}
                iconPosition="left"
                light
              />
              <SocialButton
                provider={AuthProvider.APPLE}
                responsive
                onClick={() => {
                  showModal(
                    <AuthModal
                      kind={AuthKind.LOGIN}
                      onCloseModal={closeModal}
                      defaultProvider={AuthProvider.APPLE}
                      componentSourceName={
                        LoginComponentSourceName.homeAuthBanner
                      }
                    />
                  );
                }}
              />
              <SocialButton
                provider={AuthProvider.FACEBOOK}
                responsive
                onClick={() => {
                  showModal(
                    <AuthModal
                      kind={AuthKind.LOGIN}
                      onCloseModal={closeModal}
                      defaultProvider={AuthProvider.FACEBOOK}
                      componentSourceName={
                        LoginComponentSourceName.homeAuthBanner
                      }
                    />
                  );
                }}
              />
              <GoogleLoginBtn googleClientId={googleClientId} path={asPath} />
            </ButtonsGroup>
            <TermsOfUse>
              {t('login-terms-of-use-text')}
              <ExternalAgreementLink
                agreementType={AgreementType.TERMS_OF_SERVICE}
                getAgreementLinkText={mapAgreementTypeToText}
              />
              {t('login-terms-of-use-joining')}
              <ExternalAgreementLink
                agreementType={AgreementType.PRIVACY_POLICY}
                getAgreementLinkText={mapAgreementTypeToText}
              />
            </TermsOfUse>
          </LoginSection>
        </Banner>
      </Content>
    </Container>
  );
}

export default FeedLoginBanner;
