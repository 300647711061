import React, { ReactNode, useState } from 'react';
import { SwitchToggle } from '@pelando/components';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { SpecialFeedTab } from '../../../../../services/feedTabsCategories';

import {
  Container,
  SwitchContainer,
  SwitchSkeleton,
  SwitchText,
  SwitchTextSkeleton,
  TabOptions,
} from './style';
import { FeedKindTabs } from '../../../../../components/Feed/FeedKindTabs';
import { FilterOptions } from '../../../../../../domain/entities/Filter';

export type FilterValue = Pick<
  FilterOptions,
  'hideExpired' | 'hottestFeedPeriod'
>;

export type SpecialSpecialFeedTabsProps = {
  featuredTabContent: ReactNode;
  recentsTabContent: ReactNode;
  commentedTabContent: ReactNode;
  hottestTabContent: ReactNode;
  initialTab: SpecialFeedTab;
  initialFilters: FilterValue;
  onChangeTab: (tab: SpecialFeedTab) => void;
  onChangeFilter: (filter: Partial<FilterValue>) => void;
  loading?: boolean;
  isCommunityFeed?: boolean;
};

export default function SpecialSpecialFeedTabs({
  featuredTabContent,
  recentsTabContent,
  commentedTabContent,
  hottestTabContent,
  initialTab,
  onChangeTab,
  initialFilters,
  onChangeFilter,
  loading,
  isCommunityFeed,
}: SpecialSpecialFeedTabsProps) {
  const { t } = useTranslation('feed');
  const [currentTab, setTab] = useState<SpecialFeedTab>(
    initialTab || SpecialFeedTab.FEATURED
  );

  const isActive = (tab: SpecialFeedTab) => tab === currentTab;

  const updateTab = (tab: SpecialFeedTab) => {
    onChangeTab(tab);
    setTab(tab);
  };

  const showHideExpiredSwitch = currentTab !== SpecialFeedTab.FEATURED;

  const { hideExpired, hottestFeedPeriod } = initialFilters || {};

  return (
    <Container>
      <TabOptions data-is-community-feed={isCommunityFeed}>
        <FeedKindTabs
          activeKind={currentTab}
          onChangeKind={updateTab}
          currentHottestPeriod={hottestFeedPeriod}
          onChangeHottestPeriod={(period) =>
            onChangeFilter({ hottestFeedPeriod: period })
          }
          loading={loading}
        />
        {showHideExpiredSwitch && (
          <SwitchContainer>
            {loading ? (
              <>
                <SwitchTextSkeleton animate />
                <SwitchSkeleton animate />
              </>
            ) : (
              <>
                <SwitchText>{t('expireds-filter')}</SwitchText>
                <SwitchToggle
                  onChange={() => onChangeFilter({ hideExpired: !hideExpired })}
                  checked={hideExpired}
                />
              </>
            )}
          </SwitchContainer>
        )}
      </TabOptions>

      <div>
        {isActive(SpecialFeedTab.FEATURED) && featuredTabContent}
        {isActive(SpecialFeedTab.RECENT) && recentsTabContent}
        {isActive(SpecialFeedTab.COMMENTED) && commentedTabContent}
        {isActive(SpecialFeedTab.HOTTEST) && hottestTabContent}
      </div>
    </Container>
  );
}
