export const SECOND_TO_MS = 1000;
export const MINUTE_TO_MS = SECOND_TO_MS * 60;
export const HOUR_TO_MS = MINUTE_TO_MS * 60;
export const DAY_TO_MS = HOUR_TO_MS * 24;
export const MINUTE_TO_SECONDS = 60;
export const HOUR_TO_SECONDS = MINUTE_TO_SECONDS * 60;
export const DAY_TO_SECONDS = HOUR_TO_SECONDS * 24;
export const YEAR_TO_SECONDS = DAY_TO_SECONDS * 365;

type MonthNames = {
  SHORT_MONTHS: string[];
  LONG_MONTHS: string[];
};

export const MONTHS: { [key: string]: MonthNames } = {
  'en-US': {
    SHORT_MONTHS: [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ],
    LONG_MONTHS: [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ],
  },
  'pt-BR': {
    SHORT_MONTHS: [
      'jan',
      'fev',
      'mar',
      'abr',
      'mai',
      'jun',
      'jul',
      'ago',
      'set',
      'out',
      'nov',
      'dez',
    ],
    LONG_MONTHS: [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ],
  },
};

export const TIME_DAYS: { [key: string]: { [key: string]: string } } = {
  'pt-BR': {
    now: 'agora',
    yesterday: 'ontem',
  },
  'en-US': {
    now: 'now',
    yesterday: 'yesterday',
  },
};
