import { Community } from '@/domain/entities/Communities';
import { Icon, Theme } from '@pelando/components';
import { getCommunityIconBySlug } from '@/presentation/services/communities';
import {
  Container,
  Content,
  Description,
  IconContainer,
  TextContainer,
  Title,
} from './style';

type CommunityFeedHeaderProps = {
  community: Community;
};

export default function CommunityFeedHeader({
  community,
}: CommunityFeedHeaderProps) {
  return (
    <Container>
      <Content className="maximumGrid">
        <IconContainer>
          <Icon
            icon={getCommunityIconBySlug[community.slug]}
            style={{
              fontSize: 44,
              color: `rgb(${Theme.colors.Brand})`,
            }}
          />
        </IconContainer>
        <TextContainer>
          <Title>{community.name}</Title>
          <Description>{community.description}</Description>
        </TextContainer>
      </Content>
    </Container>
  );
}
