import { OfferSortOption } from '../../../../../domain/entities/Filter';
import { ExploreTab } from '../components/ExploreFeedTabs';

export default function getSortOptionByExploreTab(filter: ExploreTab) {
  return {
    [ExploreTab.COMMENTED]: OfferSortOption.COMMENT,
    [ExploreTab.HOTTEST]: OfferSortOption.TEMPERATURE,
    [ExploreTab.RECENT]: OfferSortOption.CREATED_AT,
  }[filter];
}
