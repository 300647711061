import { Icon } from '@pelando/components';
import { Check } from '@pelando/fontawesome/icons';
import { useTranslation } from '../../../../../hooks/useTranslation';

import { FeedEndScreenContainer, FeedEndScreenInformation } from './style';

type FeedEndScreenProps = {
  categoryName: string;
  onClick: () => void;
  buttonTitle?: string;
};

export default function FinishedList({
  categoryName,
  onClick,
  buttonTitle = 'finished-list-button-title',
}: FeedEndScreenProps) {
  const { t } = useTranslation('feed');

  return (
    <FeedEndScreenContainer>
      <div>
        <FeedEndScreenInformation
          icon={<Icon icon={Check} />}
          title=""
          content={`${t('finished-list-content')}${categoryName}`}
          onClick={onClick}
          buttonTitle={t(buttonTitle)}
        />
      </div>
    </FeedEndScreenContainer>
  );
}
