import { getProbablyLoggedCookie } from '@/infra/auth/tokenManagement';
import { useFeedLoginBanner } from '../../hooks/useFeedLoginBanner';
import FeedLoginBanner from '../FeedLoginBanner';

function FeedLoginBannerEnhanced({
  googleClientId,
  isShowingPagination,
}: {
  googleClientId?: string;
  isShowingPagination?: boolean;
}) {
  const { triggerAnimation, showLoginBanner, handleAnimmation } =
    useFeedLoginBanner({
      isLogged: getProbablyLoggedCookie(),
    });

  return showLoginBanner ? (
    <FeedLoginBanner
      triggerAnimation={triggerAnimation}
      handleAnimmation={handleAnimmation}
      googleClientId={googleClientId}
      isShowingPagination={isShowingPagination}
    />
  ) : null;
}

export default FeedLoginBannerEnhanced;
