import SocialMediaButton from '@/presentation/components/Authentication/components/SocialMediaButton';
import {
  BodyHeavy,
  Button,
  CaptionLight,
  Colors,
  MediaQuery,
  SmallLight,
  Theme,
} from '@pelando/components';
import { css, styled } from 'styled-components';
import { FEED_PAGINATION_HEIGHT } from '../NewFeedPagination/styles';

const buttonsStyles = css`
  margin-right: 8px;
  height: 40px;

  width: 40px;
  padding: 0;
  i {
    margin-right: 0;
  }
  :nth-of-type(3n) {
    margin-right: 0;
  }
`;

export const Banner = styled.div`
  width: 100%;

  border-radius: 16px 16px 0px 0px;
  margin-bottom: 28px;
  background: linear-gradient(
    270deg,
    rgba(${Theme.colors.Sierra}, 1),
    rgba(${Theme.colors.Brand}, 1)
  );

  transform: translateY(150px);
  transition: transform 0.5s;

  &[data-expand='true'] {
    transform: translateY(0);
    margin-bottom: 0;
  }

  @media ${MediaQuery.SCREEN_SM} {
    display: none;
  }
`;

export const BannerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgb(${Colors.White});
  padding: 16px 24px 16px 24px;
  gap: 24px;
`;

export const Title = styled.h2`
  ${BodyHeavy}
`;

export const SubTitle = styled.p`
  ${CaptionLight}
`;

export const LoginSection = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px;
  border-color: rgb(${Colors.Silver});
  background-color: rgb(${Colors.White});
  padding: 0 32px 24px 32px;
  align-items: center;
  justify-content: center;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const SocialButton = styled(SocialMediaButton)`
  ${buttonsStyles}
`;

export const EmailButton = styled(Button)`
  ${buttonsStyles}
`;

export const TermsOfUse = styled.div`
  ${SmallLight}
  color: rgb(${Colors.Gray});
  margin-top: 16px;

  a {
    color: rgb(${Colors.Gray});
  }
`;

export const BannerButton = styled(Button)`
  max-width: 150px;

  &[data-visible='false'] {
    display: none;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;

  &[data-expand='true'] {
    align-items: center;
  }
`;

export const Container = styled.div`
  position: fixed;
  z-index: 2;
  width: 100%;
  bottom: 0;
  transition: transform 0.3s ease-in-out;

  &[data-is-showing-pagination='true'] {
    transform: translateY(-${FEED_PAGINATION_HEIGHT}px);
  }
`;

export const Content = styled.div`
  width: 100%;
  column-gap: 300px;
  @media ${MediaQuery.SCREEN_LG_UP} {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) auto;
  }
`;
