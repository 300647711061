import styled from 'styled-components';
import { CaptionHeavy, MediaQuery, Theme, ZIndex } from '@pelando/components';
import { maximumGridStyle } from '../../styles/global';
import { HeaderLogoEnhanced } from '../HeaderLogoEnhanced/style';

export const HEADER_HEIGHT = 68;

type HeaderProps = {
  $hasBanner?: boolean;
};

export const HeaderStores = styled.div`
  display: none;
  grid-area: stores;
  gap: 12px;

  @media ${MediaQuery.SCREEN_LG_UP} {
    display: flex;
    padding: 6px 4px;
  }
`;

export const HeaderText = styled.span`
  ${CaptionHeavy}
  margin-left: 6px;
`;

export const HeaderStoresItem = styled.div`
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: row;

  padding: 0px;

  &:hover {
    background-color: initial;
  }

  @media ${MediaQuery.SCREEN_SM} {
    display: none !important;
  }
`;

export const HeaderLogo = styled(HeaderLogoEnhanced)`
  height: 27px;

  svg {
    height: 27px;
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    width: 132px;
    height: 30px;

    svg {
      width: 132px;
      height: 30px;
    }
  }
`;

export const HeaderContainer = styled.header<HeaderProps>`
  position: fixed;
  left: 0px;
  right: 0px;
  height: ${HEADER_HEIGHT}px;
  z-index: ${ZIndex.HEADER_CONTAINER + 1};
  width: auto;
  background-color: rgb(${Theme.colors.Juliet});
  border: 1px solid rgb(${Theme.colors.Golf});
  top: ${({ $hasBanner }) => ($hasBanner ? '96px' : '0')};

  @media ${MediaQuery.SCREEN_MD_UP} {
    top: 0;
  }

  @media ${MediaQuery.SCREEN_MD_DOWN} {
    &[data-is-search-page='true'] {
      border-bottom: 0;
    }
  }
`;

export const HeaderContentWrapper = styled.div`
  display: grid;
  grid-area: content;
  grid-gap: 8px;
  grid-template-columns: 40px 40px auto;
  grid-template-areas: 'menu search-bar right-corner';
  align-items: center;
  justify-self: flex-end;

  &[data-logged='true'] {
    grid-template-columns: 40px auto;
    grid-template-areas: 'search-bar right-corner';
  }

  @media ${MediaQuery.SCREEN_MD_UP} {
    grid-template-columns: auto 1fr auto auto;
    grid-template-areas: 'stores search-bar menu right-corner';
    justify-self: unset;
    grid-gap: 24px;

    &[data-logged='true'] {
      grid-template-columns: auto 1fr auto;
      grid-template-areas: 'stores search-bar right-corner';
    }
  }
`;

export const HeaderContent = styled.div`
  display: grid;
  height: 100%;
  width: auto;
  grid-gap: 16px;
  grid-template-columns: 119px 1fr;
  grid-template-areas: 'logo content';
  justify-items: space-between;
  align-items: center;
  ${maximumGridStyle}

  @media ${MediaQuery.SCREEN_MD_UP} {
    grid-template-columns: 132px 1fr;
    grid-gap: 24px;
  }
`;
