import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { ScrollDirection } from '@/presentation/hooks/useScrollDirection';
import { LAST_FEED_PAGE } from '@/presentation/services/feed';
import { NewFeedPagination } from './component';
import { TABS_CONTAINER_ID } from '../..';

const removePageQueryParam = (url: string) => url.replace(/(\?|&)page=\d+/, '');

const createPages = (path: string, totalPages = LAST_FEED_PAGE) => {
  const urlWithoutPageQueryParam = removePageQueryParam(path);
  const pageCount = Math.min(totalPages, LAST_FEED_PAGE);

  return Array.from({ length: pageCount }, (_, index) =>
    index === 0
      ? urlWithoutPageQueryParam
      : `${urlWithoutPageQueryParam}?page=${index + 1}`
  );
};

export const EnhancedNewFeedPagination = ({
  currentPageIndex,
  showPagination,
  scrollDirection,
  totalPages,
}: {
  currentPageIndex: number;
  showPagination: boolean;
  scrollDirection?: ScrollDirection;
  totalPages?: number;
}) => {
  const { asPath } = useRouter();

  const pages = createPages(asPath, totalPages);

  const scrollToTabsContainer = useCallback(() => {
    const feedTabsContainer = document.getElementById(TABS_CONTAINER_ID);
    feedTabsContainer?.scrollIntoView();
  }, []);

  return (
    <NewFeedPagination
      currentPageIndex={currentPageIndex}
      onScrollToTop={scrollToTabsContainer}
      pages={pages}
      showPagination={showPagination}
      scrollDirection={scrollDirection}
    />
  );
};
