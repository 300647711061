import { ComponentProps, useEffect, useRef } from 'react';
import { Container } from './styles';

type Props = {
  onVisibilityChange: (pageIndex: number) => void;
  pageIndex: number;
} & ComponentProps<'div'>;

// 64px for the header, 108px for the feed tabs and 38px for extra padding
const ROOT_MARGIN_CONSTANT = 64 + 108 + 38;

export const PageVisibilityObserver = ({
  onVisibilityChange,
  children,
  pageIndex,
  ...rest
}: Props) => {
  const intersectionPoint = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!intersectionPoint.current) return undefined;

    const handleIntersection: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onVisibilityChange(pageIndex);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: `-${ROOT_MARGIN_CONSTANT}px 0px -${
        window.innerHeight - ROOT_MARGIN_CONSTANT
      }px 0px`,
    });

    observer.observe(intersectionPoint.current);

    return () => observer.disconnect();
  }, [onVisibilityChange, pageIndex]);

  return (
    <Container ref={intersectionPoint} {...rest}>
      {children}
    </Container>
  );
};
