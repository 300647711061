'use client';

import { ScrollNavigation, useScrollNavigation } from '@pelando/components';
import React, { useRef } from 'react';
import { ChevronLeft, ChevronRight } from '@pelando/fontawesome/icons';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import {
  CarouselArrowIcon,
  CarouselArrowLeft,
  CarouselArrowRight,
  Container,
  StoreCard,
  CarouselNavigation,
  CarouselContainer,
  StoreCardSkeleton,
  Title,
} from './style';
import { FeaturedStores } from '../../../../../../domain/entities/Stores';

export type CarouselFeaturedStore = Omit<FeaturedStores, 'slug' | 'image'>;

export type FeaturedStoresCarouselComponentProps = {
  stores: CarouselFeaturedStore[];
  className?: string;
};

function FeaturedStoresCarouselComponent({
  stores,
  className,
}: FeaturedStoresCarouselComponentProps) {
  const { t } = useTranslation('feed');
  const navigationRef = useRef<HTMLDivElement>(null);
  const { arrowsNavigation, scroll, updateNavigators } =
    useScrollNavigation(navigationRef);

  const renderStoreList = () =>
    stores.map(({ id, name, url }) => (
      <StoreCard key={id} link={url}>
        {name}
      </StoreCard>
    ));

  const renderStoreListSkeleton = () => {
    const keys = [...Array(14)].map((_, index) => index);

    return keys.map((key) => (
      <span key={key} aria-busy="true">
        <StoreCardSkeleton />
      </span>
    ));
  };

  const isLoading = !stores.length;

  const carouselContent = isLoading
    ? renderStoreListSkeleton()
    : renderStoreList();

  return (
    <Container className={className}>
      <Title>{t('featured-stores-carousel-title')}</Title>
      <CarouselContainer
        data-forward-enabled={arrowsNavigation.forward}
        data-back-enabled={arrowsNavigation.back}
      >
        {!isLoading && (
          <>
            <CarouselArrowLeft
              onClick={() => scroll(ScrollNavigation.BACK)}
              disabled={!arrowsNavigation.back}
              aria-hidden={!arrowsNavigation.back}
            >
              <CarouselArrowIcon
                icon={ChevronLeft}
                aria-label={t('featured-stores-carousel-arrow-left')}
              />
            </CarouselArrowLeft>
            <CarouselArrowRight
              onClick={() => scroll(ScrollNavigation.FORWARD)}
              disabled={!arrowsNavigation.forward}
              aria-hidden={!arrowsNavigation.forward}
            >
              <CarouselArrowIcon
                icon={ChevronRight}
                aria-label={t('featured-stores-carousel-arrow-right')}
              />
            </CarouselArrowRight>
          </>
        )}
        <CarouselNavigation ref={navigationRef} onScroll={updateNavigators}>
          {carouselContent}
        </CarouselNavigation>
      </CarouselContainer>
    </Container>
  );
}

export default FeaturedStoresCarouselComponent;
